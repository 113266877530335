import { firebase } from "./firebase";
import { db } from "./db";
import { getLog } from "./log";
// eslint-disable-next-line no-unused-vars
let log = getLog("dbutils");

export async function createOrOverride(col, id, data) {
  if (id) {
    await col.doc(id).set(data);
    return await loadObj(col.doc(id));
  } else {
    let ref = await col.add(data);
    return await loadObj(ref);
  }
}

// Fails if object already exist
export async function createSafely(col, id, data) {
  if (id) {
    let doc = await col.doc(id).get();
    if (doc.exists) {
      log.log(`doc ${id} already exists`);
      return null;
    }
    await col.doc(id).set(data);
    return await loadObj(col.doc(id));
  } else {
    let ref = await col.add(data);
    return await loadObj(ref);
  }
}

export async function deleteCollection(col) {
  const qs = await col.get();
  let batch = db.batch();
  var count = 0;
  qs.forEach((msg) => {
    // Limiting to 10000 deletes to avoid costly mistakes
    if (count >= 10000)
      return;
    if (count >= 500) {
      batch.commit();
      batch = db.batch();
      count = 0;
    }
    batch.delete(msg.ref);
    count += 1;
  });
  batch.commit();
  if (count >= 10000)
    log.error("deleteCollection is limited to 10,000 deletions");
  return qs.size;
}

export async function databaseDeleteChildren(ref) {
  let count = 0;
  ref.limitToFirst(10000).on("child_added", function (snapshot) {
    log.log("deleting", snapshot.key);
    snapshot.ref.remove();
    count += 1;
  });
  if (count >= 10000)
    log.error("databaseDeleteChildren is limited to 10,000 deletions");
  return count;
}

export async function collectionFromIds(col, docIds) {
  if (docIds.length > 10)
    log.error("Only supports 10 users in event. use getCollectionDocsFromIds");
  return await col.where(firebase.firestore.FieldPath.documentId(), "in", docIds)
}

export async function getCollectionDocsFromIds(col, docIds) {
  var res = [];
  let ids = [...docIds];
  //log.log("ids", ids);
  while (ids.length > 0) {
    let someids = ids.splice(0, 10);
    //log.log("someids", someids);
    let docsqs = await col.where(firebase.firestore.FieldPath.documentId(), "in", someids).get();
    docsqs.forEach((doc) => {
      res.push({ ...doc.data(), id: doc.id });
    });
  }
  //log.log("getCollectionDocsFromIds", res);
  return res;
}

export function whereDocIdStartWith(col, prefix) {
  //log.log("whereDocIdStartWith", prefix);
  return col
    .where(firebase.firestore.FieldPath.documentId(), '>=', prefix)
    .where(firebase.firestore.FieldPath.documentId(), '<=', prefix + '\uf8ff');
}

export async function deleteDocsInCollection(col, ids) {
  let batch = db.batch();
  ids.forEach((id) => {
    batch.delete(col.doc(id));
  });
  batch.commit();
}

export async function loadObj(docPath) {
  let doc = await docPath.get();
  if (!doc.exists)
    return null;
  return { ...doc.data(), id: doc.id };
}

export async function loadMap(coll) {
  let snapshot = await coll.get();
  return Object.fromEntries(snapshot.docs.map(doc => [doc.id, { ...doc.data(), id: doc.id }]));
}

export async function loadArr(coll) {
  let snapshot = await coll.get();
  return snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } });
}

import { mergeDeep } from "./utils";

// Careful about circular dependencies, does not check
export async function inheritedObj(col, id, init, parentField = 'template') {
  log.log("inheritedObj", id);
  let obj = init || await loadObj(col.doc(id));
  log.log("loadedObj", id, obj);
  let parent = obj[parentField];
  if (!parent)
    return obj;
  let parentObj = await inheritedObj(col, parent, null, parentField);
  let merged = mergeDeep(parentObj, obj);
  log.log("merged", merged);
  return merged;
}

export async function bindInheritedObj(that, prop, col, id, fun = ((id) => id)) {
  that.$set(that, prop, fun(await inheritedObj(col, id)));
  let listener = col.doc(id).onSnapshot(async (doc) => {
    let obj = { ...doc.data(), id: doc.id };
    log.log("bindInheritedObj doc=", obj);
    let res = fun(await inheritedObj(col, id, obj));
    log.log("bindInheritedObj res=", res);
    that.$set(that, prop, res);
  });
  return listener;
}

export async function calculateFolderSize(ref) {
  let res = await ref.listAll();
  let files = res.items.map(async (itemRef) => {
    let meta = await itemRef.getMetadata();
    return meta.size;
  });
  let subs = res.prefixes.map(async (folderRef) => {
    return await calculateFolderSize(folderRef);
  })
  let subsizes = await Promise.all([...files, ...subs]);
  let subsize = subsizes.reduce((acc, v) => acc += v, 0);
  return subsize;
}

export function humanFileSize(bytes, si = false, dp = 2) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + ' ' + units[u];
}
